import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import Stories from "react-insta-stories";
import Welcome from "./Welcome";
import FreeViewing from "./FreeViewing";
import SubscriptionOptions from "./SubscriptionOptions";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";

function StoriesContainer({ isOpen, onClose }) {
  const [isMobile, setIsMobile] = useState(false);

  const storiesArr = [
    {
      content: () => <Welcome />,
    },
    {
      content: () => <FreeViewing />,
    },
    {
      content: () => <SubscriptionOptions onClose={onClose} />,
    },
  ];

  // detect screen size
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  // lock scroll
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;
  return createPortal(
    <>
      {isMobile ? (
        <div className="stories-mobile-content">
          <div className="stories-mobile-body">
            <div className="stories-close-wrapper">
              <CloseIcon onClick={onClose} />
            </div>
            <Stories
              stories={storiesArr}
              defaultInterval={8000}
              width="100%"
              height="100%"
              loop
            />
          </div>
        </div>
      ) : (
        <div className="stories-overlay" onClick={onClose}>
          <div
            className="stories-container"
            onClick={(e) => e.stopPropagation()}
          >
            <Stories
              stories={storiesArr}
              defaultInterval={8000}
              width="100%"
              height="100%"
              loop
            />
          </div>
        </div>
      )}
    </>,
    document.body,
  );
}

export default StoriesContainer;
