import React from "react";
import { useTranslation } from "react-i18next";

// 2
function FreeViewing() {
  const { t } = useTranslation();
  return (
    <div className="story-component free-viewing">
      <div className="free-viewing-content-wrapper">
        <h1>{t("exploreForFree")}</h1>
        <h2>{t("pagesForFree")}</h2>
        <span>{t("wantMore")}</span>
        <p>{t("unlockFullList")}</p>
        <div className="diamond">
          <img width="65%" src="/diamond.gif" alt="Locked content" />
        </div>
      </div>
    </div>
  );
}

export default FreeViewing;
