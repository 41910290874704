import React from "react";
import { createInvoiceLink } from "../../services/api";
import SubscriptionTable from "../SubscriptionTable";
import { useTranslation } from "react-i18next";

const handleSubscribe = async (type, action) => {
  const result = await createInvoiceLink(type, null);
  if (result.ok) {
    if (window.Telegram?.WebApp) {
      try {
        window.Telegram.WebApp.openInvoice(result.result);
      } catch (error) {
        window.open(result.result, "_blank");
      }
    } else {
      window.open(result.result, "_blank");
    }
  }
  window.ym(98682253, "reachGoal", "requestSubscribe", { type });
  return action();
};

// 3
function SubscriptionOptions({ onClose }) {
  const { t } = useTranslation();

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div className="story-component subscription-options">
      <div className="subscription-options-content-wrapper">
        <div className="subscription-options-content">
          <h1>{t("chooseSubscription")}</h1>
          <h2>{t("getFullAccess")}</h2>
          {SubscriptionTable({ handleSubscribe, onClose })}
          <button
            type="button"
            className="subscription-limited-button"
            onClick={handleClose}
          >
            {t("continueFree")}
          </button>
        </div>
        <div className="rocket-wrapper">
          <span>{t("letsGetStarted")}</span>
          <div className="rocket">
            <img src="/rocket-launch-cropped.gif" alt="Rocket" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionOptions;
