import React from "react";
import { useTranslation } from "react-i18next";

// 1
function Welcome() {
  const { t } = useTranslation();
  return (
    <div className="story-component welcome">
      <div className="welcome-content-wrapper">
        <h1>{t("welcome")}</h1>
        <h2>{t("analyzeUsernames")}</h2>
        <p>{t("aiCategorizes")}</p>
        <div className="robot">
          <img width="65%" src="/robot.gif" alt="AI robot" />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
