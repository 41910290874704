import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoImage } from "../assets/LogoImage.svg";
import { ReactComponent as IconSearch } from "../assets/IconSearch.svg";
import TelegramLoginButton from "react-telegram-login";
import {
  onTelegramAuth,
  telegramAuth,
  telegramBotName,
} from "../utils/telegramAuth";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

const getAvatarSymbols = (authUser) => {
  let source = authUser?.username || authUser?.first_name;
  if (source.length < 2) {
    if (source.length === 0) {
      source = "NA";
    } else {
      source += ".";
    }
  }
  return source[0] + source[1];
};

const Navbar = ({ isTelegram, authUser, submitSearch, getSearchQuery }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(getSearchQuery());

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Debounced search function
  const debouncedSearch = useCallback(debounce(submitSearch, 300), [
    submitSearch,
  ]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(e);
  };

  return (
    <nav>
      <div className="nav-header">
        <div>
          <Link reloadDocument to="/">
            <LogoImage className="logo-icon" />
          </Link>
        </div>
        <LanguageSelector />
      </div>
      <div
        className={isTelegram ? "nav-items nav-items-telegram" : "nav-items"}
      >
        <div className="search">
          <label className="search-label">
            <IconSearch />
            <input
              placeholder={t("searchPlaceholder")}
              className="search-input"
              tabIndex="-1"
              autoComplete="off"
              enterKeyHint="search"
              onChange={handleSearchChange}
              onKeyUp={(e) => (e.key === "Enter" ? submitSearch(e) : null)}
              value={searchTerm}
            />
          </label>
        </div>
        {authUser && (
          <div className="profile-menu">
            <span>{getAvatarSymbols(authUser)}</span>
          </div>
        )}
        {!isTelegram && !authUser && (
          <div className="connect-telegram">
            <TelegramLoginButton
              dataOnauth={onTelegramAuth}
              onClick={(e) => {
                e.preventDefault();
                telegramAuth();
              }}
              botName={telegramBotName}
              cornerRadius={10}
            />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
