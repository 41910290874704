import React from "react";

const SubscriptionTable = ({ handleSubscribe, onClose }) => {
  const handleButtonClick = (type) => (e) => {
    e.stopPropagation();
    handleSubscribe(type, onClose);
  };

  return (
    <table
      className="subscription-table"
      style={{ width: "100%", borderCollapse: "collapse", fontWeight: "bold" }}
    >
      <tbody>
        <tr>
          <td style={{ padding: "10px 0" }}>Day</td>
          <td style={{ padding: "10px 0", textAlign: "right" }}>⭐</td>
          <td style={{ padding: "10px 0", textAlign: "center" }}>350</td>
          <td style={{ padding: "10px 0", textAlign: "left" }}>Stars</td>
          <td
            style={{
              padding: "10px 0",
              textAlign: "right",
              fontWeight: "normal",
            }}
          >
            <button
              type="button"
              className="subscription-table-button"
              onClick={handleButtonClick("1day")}
              style={{ padding: "8px 16px", cursor: "pointer" }}
            >
              Subscribe
            </button>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px 0" }}>Week</td>
          <td style={{ padding: "10px 0", textAlign: "right" }}>⭐</td>
          <td style={{ padding: "10px 0", textAlign: "center" }}>1500</td>
          <td style={{ padding: "10px 0", textAlign: "left" }}>Stars</td>
          <td
            style={{
              padding: "10px 0",
              textAlign: "right",
              fontWeight: "normal",
            }}
          >
            <button
              type="button"
              className="subscription-table-button"
              onClick={handleButtonClick("1week")}
              style={{ padding: "8px 16px", cursor: "pointer" }}
            >
              Subscribe
            </button>
          </td>
        </tr>
        <tr>
          <td style={{ padding: "10px 0" }}>Month</td>
          <td style={{ padding: "10px 0", textAlign: "right" }}>⭐</td>
          <td style={{ padding: "10px 0", textAlign: "center" }}>5000</td>
          <td style={{ padding: "10px 0", textAlign: "left" }}>Stars</td>
          <td
            style={{
              padding: "10px 0",
              textAlign: "right",
              fontWeight: "normal",
            }}
          >
            <button
              type="button"
              className="subscription-table-button"
              onClick={handleButtonClick("1month")}
              style={{ padding: "8px 16px", cursor: "pointer" }}
            >
              Subscribe
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SubscriptionTable;
